<template>
  <div class="columns">
    <div class="column is-one-third">
      <card-component
        title="1. Display correlations of variables with components"
        class="tile is-child"
        :displayHelp="helpname"
      >
        <validation-observer ref="r_plotvar_observer" v-slot="{ handleSubmit }">
          <validation-provider
            v-slot="{ errors, valid}"
            :rules= {required:true,integer:true,min_value:1,max_value:nbCP,excluded:axisy}
            :customMessages = "{excluded: 'The 2 axes must be different. Choose another component.',
            max_value: 'Only '+nbCP+' components have been computed.'}"
            name="Component to display on X (horizontal)"
          >
            <b-field
              label="Component to display on X (horizontal)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-input
                v-model="axisx"
                @focus="$event.target.select()"
                expanded
              ></b-input>
            </b-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors, valid}"
            :rules= {required:true,integer:true,min_value:1,max_value:nbCP,excluded:axisx}
            :customMessages = "{excluded: 'The 2 axes must be different. Choose another component.',
            max_value: 'Only '+nbCP+' components have been computed.'}"
            name="Component to display on Y (vertical)"
          >
            <b-field
              label="Component to display on Y (vertical)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-input
                v-model="axisy"
                @focus="$event.target.select()"
                expanded
              ></b-input>
            </b-field>
          </validation-provider>
          <hr/>
          <b-field
            v-if="analysisType === 'pls' && plsMode === 'canonical'"
            label="Overlap X- and Y-representation plots"
          >
            <b-select
              v-model="mode"
              expanded
            >
              <option value="separated">Separated</option>
              <option value="together">Together</option>
            </b-select>
          </b-field>
          <b-field label="Correlation threshold">
            <b-slider
              v-model="thresholdCor"
              :min=0
              :max=1
              :step=0.01
              :tooltip=true
              :indicator=true
              type="is-grey"
            >
              <b-slider-tick :value="0">0</b-slider-tick>
              <b-slider-tick :value="1">1</b-slider-tick>
            </b-slider>
          </b-field>
          <hr />
          <div class="has-text-centered">
            <b-button rounded
              class="is-primary button-shadow"
              @click.prevent="handleSubmit(run)"
            >
              <span class="icon is-small"><i class="fa fa-cogs"></i></span>
              <span> Plot variables </span>
            </b-button>
          </div>
        </validation-observer>
      </card-component>
    </div>
    <div class="column">
      <card-component
        :title="titleResult">
      </card-component>
      <error :type='"notifications"' />
      <card-no-result v-if="!resultsLoaded">
        <strong>How to set options?<br></strong>
        By choosing a correlation threshold, only variables with a correlation
        larger than this number are displayed on the plot (to make it easier to
        read).
      </card-no-result>
      <card-component v-else-if="hasResults">
        <plotly
          v-if="graphicals.length===1"
          :data="graphicals[0]"
        ></plotly>
        <plotly-list
          v-else-if="graphicals.length>1"
          :data="graphicals"
        ></plotly-list>
        <nav class="level">
          <div class="level-left" />
          <div class="buttons level-right">
            <r-save-for-report
              label="Add to report"
              :objectName="datasetName"
              :params="params"
            />
          </div>
        </nav>
      </card-component>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { ranalysis } from '@/mixins/ranalysis'
import { useWorkspaceStore } from '@/stores/workspacestore'

export default {
  name: 'RPlotvar',
  props: {
    analysisType: {
      type: String,
      default: ''
    },
    selectedDataset: {
      type: String,
      default: null
    },
    datasetName: {
      type: String,
      default: ''
    },
    nbCP: {
      type: Number,
      default: 0
    },
    plsMode: {
      type: String,
      default: ''
    }
  },
  components: {
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'plotly-list': () => import('@/components/ui/PlotlyList.vue'),
    'plotly': () => import('@/components/rresults/Plotly.vue'),
    'r-save-for-report': () => import('@/components/RSaveForReport.vue'),
    'error': () => import('@/components/ui/Error.vue')
  },
  mixins: [ranalysis],
  data () {
    return {
      axisx: 1,
      axisy: 2,
      thresholdCor: 0.8,
      mode: 'separated'
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore }
  },
  computed: {
    titleResult: function () {
      if (this.selectedDataset) {
        let username = this.workspaceStore.datasets.filter(obj => obj.object_name === this.selectedDataset)
        return '2. Plot the correlations of variables from ' + username[0].user_name + ' with components'
      } else {
        return '2. Plot the correlation of variables with components'
      }
    },
    params: function () {
      let funcName = 'r_plotvar'
      if (this.analysisType.startsWith('pls')) {
        funcName = 'r_plotplsvar'
      }
      let params = {
        'func_name': funcName,
        'datasetName': this.datasetName,
        'axis1': parseInt(this.axisx),
        'axis2': parseInt(this.axisy),
        'threshold.cor': this.thresholdCor
      }
      if (this.analysisType === 'pls') {
        params['mode'] = this.mode
      }
      return params
    },
    helpname: function () {
      if (this.$route.name === 'mfa' || this.analysisType === 'mfa') {
        return 'RPlotvarMFA'
      } else if (this.$route.name === 'pls' || this.analysisType === 'pls') {
        return 'RPlotvarPLS'
      } else if (this.$route.name === 'plsda' || this.analysisType === 'plsda') {
        return 'RPlotvarPLSDA'
      } else {
        return this.$options.name
      }
    }
  }
}
</script>
<style>
.b-slider .b-slider-track {
    height: 0.5rem;
    background-color: #20BFF0;
}
</style>
